body {
  background-color: #efb8ab;
  font-family: 'Poppins', sans-serif;
}

.wrapper {
  font-size: 20px;
  max-width: 1300px;
  margin: 0 auto;
  padding: 40px;
  background-color: whitesmoke;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  position: relative;
  overflow: hidden;
  opacity: 0; /* Начальная непрозрачность для анимации */
  transform: translateY(20px); /* Смещение вниз для анимации */
  animation: fadeInUp 0.8s forwards; /* Анимация при загрузке */
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.heading {
  color: black;
  font-size: 32px;
  font-family: cursive;
  font-weight: 700;
  margin: 20px 0;
  text-align: center;
  position: relative;
}

.image {
  max-width: 250px;
  height: auto;
  border-radius: 10px;
  margin: 20px auto;
  display: block;
}

.about,
.contact {
  margin: 20px 0;
}

.strong {
  font-weight: 900;
  color: black;
  font-family: cursive;
  font-size: 24px;  
}

.telegramLink {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s; /* Плавный переход цвета */
}

.telegramLink img {
  margin-right: 5px;
  width: 24px;
}

.telegramText {
  margin-left: 5px; /* Отступ между логотипом и текстом */
}

.telegramLink:hover {
  color: rgb(5, 5, 160) /* Цвет текста при наведении */
}

.contactInfo {
  margin: 10px 0;
  text-align: center;
  font-size: 16px;
}

@media (max-width: 600px) {
  .wrapper {
    padding: 20px;
    font-size: 16px; /* Уменьшаем шрифт для мобильных устройств */
  }

  .heading {
    font-size: 28px; /* Уменьшаем размер заголовка */
    font-family: cursive;
    color: black;
    font-weight: 900;
  }

  .image {
    max-width: 80%; /* Увеличиваем ширину изображения для мобильных */
  }

  .telegramLink {
    font-size: 16px; /* Уменьшаем размер текста ссылки для мобильных */
  }

  .contactInfo {
    font-size: 14px; /* Уменьшаем размер текста контактной информации */
  }
}

/* Стили для планшетов */
@media (min-width: 601px) and (max-width: 900px) {
  .wrapper {
    padding: 30px;
    font-size: 18px; /* Размер шрифта для планшетов */
  }

  .heading {
    font-size: 30px; /* Размер заголовка для планшетов */
    font-family: cursive;
    color: black;
    font-weight: 900;
  }

  .telegramLink {
    font-size: 17px; /* Размер текста ссылки для планшетов */
  }

  .contactInfo {
    font-size: 15px; /* Размер текста контактной информации для планшетов */
  }
}

/* Стили для десктопов (больше 900px) */
@media (min-width: 901px) {
  .wrapper {
    padding: 40px;
    font-size: 20px; /* Размер шрифта для десктопов */
  }

  .heading {
    font-size: 32px; /* Размер заголовка для десктопов */
    font-weight: 900;
  }

  .telegramLink {
    font-size: 18px; /* Размер текста ссылки для десктопов */
  }

  .contactInfo {
    font-size: 16px; /* Размер текста контактной информации для десктопов */
  }
}