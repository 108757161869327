.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
  background-color: transparent;
}

@media (max-width: 768px) {
  .header {
    margin-bottom: 0;
  }
}

.headerContent {
  align-items: baseline;
  justify-content: space-between;
}
