.tag {
  display: inline-block;
  padding: 4px 12px;
  font-weight: 500;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1;
  border-radius: 24px;
  margin-right: 8px;
  position: relative;
  background-color: #f5e2ea;
}

.tag::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  background-color: #ffb8b1;
  z-index: -1;
}
