.single-card {
  display: inline flow-root list-item;
  text-align: left;
  max-width: 680px;
  list-style: none;
  font-size: 23px;
  min-height: calc(100vh - 210px);
  font-size: 18px;
  margin: 0 auto;
  padding: 40px;
  background-color: whitesmoke;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  position: relative;
  overflow: hidden;
  transform: translateY(20px);
  animation: fadeInUp 0.8s forwards;
}

.single-card__buttons {
  display: flex;
  margin-bottom: 40px;
}

.single-card__link {
  color: black;
  font-size: 16px;
}

.single-card__button {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.single-card__button svg {
  margin-right: 5px;
}

.single-card__button:last-child {
  margin-right: 0px;
}

.single-card__edit {
  margin-left: 40px;
  color: black;
  text-decoration: underline;
}

.single-card__text, .single-card__text_with_link {
  display: flex;
  align-items: center;
}

.single-card__text svg {
  margin-right: 12px;
}

.single-card__image {
  align-self: flex-start;
  border-radius: 15px;
  max-width: 100%;
}

.single-card__info {
  display: flex;
  flex-direction: column;
  width: 100%;
}


.single-card__header-info {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0 0 18px;
}

.single-card__title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;

  line-height: 49px;
  color: black;
  margin: 0;
}

.video {
  width: 100%;
  border-radius: 15px;
}

@media screen and (max-width: 820px) {
  .single-card__text_with_link {
    display: block;
  }

  .single-card__edit {
    margin-left: 0px;
    margin-top: 20px;
    display: inline-block;
  }

  .single-card {
    display: block;
  }

  .single-card__info {
    padding-left: 0px;
  }

  .single-card__image {
    margin-bottom: 40px;
  }

  .single-card__buttons {
    display: block;
  }

  .single-card__button {
    margin-bottom: 16px;
  }
}

