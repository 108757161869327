.checkboxGroup {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  margin-right: 840px;
  font-family: Poppins;
  font-style: oblique;
}

.label {
  flex: 1;
  text-align: left;
}

.checkboxGroupItems {
  flex-basis: 370px;
}

@media (max-width: 768px) {
  .checkboxGroup {
    margin-right: 0;
  }
}
