.menuButton {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  height: auto;
  line-height: 48px;
  border-radius: 6px;
  padding: 0px 24px;
  text-decoration: none;
  border: 1px solid transparent;
  transition: background 0.2s;
  outline: none;
  user-select: none;
  cursor: pointer;
  width: max-content;
  background: rgb(211, 179, 137);
  color: #FFF;
  transform: translateY(-9px);
  align-items: baseline;
}

.menuButton.active,
.menuButton:hover {
  background: rgb(211, 179, 137);
  color:#FFF;
}

.menuLink {
  white-space: nowrap;
  color: #000;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  display: inline-block;
  padding: 0;
  font-weight: 500;
  line-height: 20px;
  margin-right: 28px;
  align-items: baseline;
}

.menulink_active,
.menuLink:hover {
  border-bottom: 1px solid #FFF;
  color: #FFF;
}

.menuLink:last-child {
  margin-right: 0px;
}

.menu {
  align-items: center;
  display: flex;
  margin-left: 1050px;
}

@media screen and (max-width: 990px) {
  .menuLink {
    font-size: 14px;
    margin-right: 14px;
  }

  .menuButton {
    font-size: 14px;
    line-height: 24px;
    height: 24px;
    line-height: 22px;
    padding: 0px 10px;
  }
}

@media screen and (max-width: 900px) {
  .menuLink {
    font-size: 14px;
    margin-right: 8px;
  }
}

@media screen and (max-width: 770px) {
  .menu {
    flex-direction: row;
    margin-top: 5px;
    margin-left: 11px;
  }

  .menuLink {
    padding-bottom: 0px;
    font-size: 12px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .menuButton {
    margin-top: 0px;
    display: none;
  }
}