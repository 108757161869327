html {
  position: relative;
  padding: 0;
  margin: 0;
}



body {
  font-family: "TeX Gyre Adventor";
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  color: wheat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #5b5050;
  padding: 0;
  margin: 0;
  margin-bottom: 100px; /* Margin bottom by footer height */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
