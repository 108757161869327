.footer {
  height: 100px;
  min-height: 6vh;
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer__brand {
    font-family: 'Great Vibes', cursive;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: black;
    text-decoration: none;
    align-items: center;
    display: flex;
    transition: color 0.3s ease-in-out;
    cursor: pointer;
    margin-left: 15px;
    margin-right: 15px;
    margin-block: auto;
}

.footer__brand:hover {
    color: rgb(23, 118, 173);
    border-bottom-color: rgb(23, 118, 173);
}

.footer__container {
    display: flex;
    justify-content: space-between;
    align-items: last baseline;
    height: 100%;
    padding: 0;
}

.footer__item {
    margin-right: 40px;
}

.footer__items {
    display: flex;
    list-style: none;
    padding: 0;
}

.footer__link {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.footer__icon {
    margin-right: 5px;
}
