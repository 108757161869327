.description {
  padding-bottom: 20px;
}

.description__title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: black;
  margin: 0 0 12px;
}


@media screen and (max-width: 820px) {
  .description {
    padding-bottom: 30px;
  }
}