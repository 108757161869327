@media screen and (max-width: 770px) {
  .main {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.main {
  min-height: calc(100vh - 170px);
}