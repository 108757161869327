.form {
  max-width: 770px;
  border: 1px solid #EEE;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 54px 100px 70px 68px;
}

@media screen and (max-width: 730px) {
  .form {
    padding: 20px;
  }
}