.container {
  background: #FFF;
  border: 1px solid rgb(211, 179, 137);
  box-sizing: border-box;
  max-height: 100px;
  overflow-y: scroll;
  padding: 10px;
  position: absolute;
  top: 42px;
  left: 230px;
  min-width: 220px;
  text-align: left;
}

@media screen and (max-width: 820px) {
  .container {
    left: 0;
    top: 70px;
  }
}
